<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          novedad.idNovedades === 0 ? "Crear Novedad" : "Modificar Novedad"
        }}</b-card-title
      >
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox
            id="vi-activo"
            v-model="novedad.estado"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="RssIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-nombre"
                placeholder="Nombre de la novedad"
                v-model="novedad.nombre"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Tipo" label-for="vi-tipo">
            <v-select
              id="vi-tipo"
              v-model="novedad.tipo"
              :options="tipos"
              label="title"
              :clearable="false"
              :reduce="(tipo) => tipo.title"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Link" label-for="vi-externo">
            <b-form-checkbox
              id="vi-externo"
              v-model="novedad.externo"
              class="custom-control-primary"
              name="check-button-ext"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col md="10">
          <b-form-group
            label="Ruta URL externa"
            label-for="vi-nombre"
            v-if="novedad.externo"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="Link2Icon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-url"
                placeholder="Ruta url externa"
                v-model="novedad.ruta"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Subir archivo" label-for="vi-file" v-else>
            <p v-if="uploadingFile">Subiendo archivo...</p>
            <b-form-file
              id="vi-file"
              v-else
              :accept="fileType()"
              :placeholder="novedad.ruta"
              @change="onChangeFile"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button
            block
            @click="saveData"
            variant="primary"
            class="mr-1"
            :disabled="loading || uploadingFile"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BButton,
  BSpinner,
  BFormFile,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormRadioGroup,
  BFormSpinbutton,
  BInputGroupPrepend,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BButton,
    vSelect,
    BSpinner,
    BFormFile,
    BCardBody,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BInputGroup,
    BButtonGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    BFormSpinbutton,
    BInputGroupPrepend,
  },
  props: {
    novedad: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      uploadingFile: false,
      tipos: [
        {
          title: "Contabilidad",
          icon: "BookIcon",
        },
        {
          title: "Documento",
          icon: "FileTextIcon",
        },
        {
          title: "Video",
          icon: "YoutubeIcon",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    fileType() {
      switch (this.novedad.tipo) {
        case "Contabilidad":
          return ".xlsx, .xls";
        case "Documento":
          return ".xlsx, .xls";
        case "Video":
          return ".mp4";
        default:
          break;
      }
    },
    onChangeFile(element) {
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        this.uploadFile(fileToLoad);
      }
    },
    uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.uploadingFile = true;
      this.$http
        .post("/api/Administracion/upload/novedad", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        })
        .then((res) => {
          this.uploadingFile = false;
          if (res.data.bOk) {
            this.novedad.ruta = res.data.url;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al subir el archivo (${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.uploadingFile = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir el archivo (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    saveData(e) {
      e.preventDefault();
      const self = this;
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/NovedadInformativa",
          body: JSON.stringify({
            opt: this.novedad.idNovedades === 0 ? "I" : "U",
            ruta: this.novedad.ruta,
            tipo: this.novedad.tipo,
            estado: this.novedad.estado,
            nombre: this.novedad.nombre,
            externo: this.novedad.externo,
            IDNovedades: this.novedad.idNovedades,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.loading = false;
          if (res.data.bOk) {
            self.refreshData();
            setTimeout(() => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Exito",
                  icon: "CheckIcon",
                  text:
                    self.novedad.idNovedades === 0
                      ? "Se creo el novedad correctamente"
                      : "Se actualizo correctamente el novedad.",
                  variant: "success",
                },
              });
            }, 500);
            if (self.novedad.id === 0) {
              self.novedad.idNovedades = res.data.iDtemp;
            }
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text:
                  self.novedad.id === 0
                    ? `Ocurrio un error al Crear el novedad (${res.data.mensaje}).`
                    : `Ocurrio un error al actualizar el novedad (${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                self.novedad.id === 0
                  ? `Ocurrio un error al Crear el novedad (${e.message}).`
                  : `Ocurrio un error al actualizar el novedad (${e.message}).`,
              variant: "danger",
            },
          });
          self.loading = false;
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

