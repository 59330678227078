<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener las Novedads {{ error }}
      </div>
    </b-alert>

    <div style="text-align: center; margin: 45vh 0" v-if="loading">
      <b-spinner label="Loading..." />
    </div>

    <b-row v-else>
      <b-col cols="12" md="6">
        <b-card>
          <b-card-header class="p-0">
            <b-card-title>Novedades Informativas</b-card-title>
            <b-button @click="createBrand" variant="primary"> Crear </b-button>
          </b-card-header>

          <div class="custom-search d-flex justify-content-end mt-2">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Buscar</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Buscar"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :rows="rows"
            :columns="columns"
            @on-row-click="onNovedadClick"
            :sort-options="{
              enabled: false,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Estado -->
              <span v-if="props.column.field === 'estado'">
                <b-badge :variant="estadoVariant(props.row.estado)">
                  {{ estadoText(props.row.estado) }}
                </b-badge>
              </span>
              <!-- Column: Otras Columnas -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Cantidad de registros </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <crear-editar
          v-if="selectedBrand !== null"
          :novedad="selectedBrand"
          :refreshData="getNovedadData"
        />
        <div v-else class="iconEmpty">
          <feather-icon icon="RssIcon" size="100" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-error"
      ok-only
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      ref="modal-error"
      title="Error"
    >
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BAvatar,
  BButton,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BPagination,
  BFormSelect,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import CrearEditar from "./CrearEditar.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BAvatar,
    BButton,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    CrearEditar,
    BPagination,
    BFormSelect,
    BCardHeader,
    VueGoodTable,
    BDropdownItem,
  },
  data() {
    return {
      rows: [],
      error: "",
      loading: true,
      columns: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Tipo",
          field: "tipo",
        },
        {
          label: "Estado",
          field: "estado",
        },
      ],
      searchTerm: "",
      pageLength: 10,

      selectedBrand: null,
    };
  },
  computed: {
    estadoText() {
      return (s) => (s ? "Activo" : "Inactivo");
    },
    estadoVariant() {
      return (s) => (s ? "light-success" : "light-secondary");
    },
  },
  methods: {
    createBrand() {
      this.selectedBrand = null;
      this.$nextTick(() => {
        this.selectedBrand = {
          idNovedades: 0,
          estado: false,
          nombre: "",
          tipo: "Contabilidad",
        };
      });
    },
    onNovedadClick(params) {
      const NovedadAUX = JSON.parse(JSON.stringify(params.row));
      this.selectedBrand = NovedadAUX;
    },
    getNovedadData() {
      const self = this;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/NovedadInformativa",
          body: JSON.stringify({
            opt: "V",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lsNovedades;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          self.loading = false;
          this.error = e.message;
        });
    },
  },
  created() {
    this.getNovedadData();
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
