var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.novedad.idNovedades === 0 ? "Crear Novedad" : "Modificar Novedad"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.novedad.estado,
      callback: function callback($$v) {
        _vm.$set(_vm.novedad, "estado", $$v);
      },
      expression: "novedad.estado"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RssIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre de la novedad"
    },
    model: {
      value: _vm.novedad.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.novedad, "nombre", $$v);
      },
      expression: "novedad.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo",
      "label-for": "vi-tipo"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo",
      "options": _vm.tipos,
      "label": "title",
      "clearable": false,
      "reduce": function reduce(tipo) {
        return tipo.title;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var title = _ref.title,
          icon = _ref.icon;
        return [_c('feather-icon', {
          staticClass: "align-middle mr-50",
          attrs: {
            "icon": icon,
            "size": "16"
          }
        }), _c('span', [_vm._v(" " + _vm._s(title))])];
      }
    }]),
    model: {
      value: _vm.novedad.tipo,
      callback: function callback($$v) {
        _vm.$set(_vm.novedad, "tipo", $$v);
      },
      expression: "novedad.tipo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Link",
      "label-for": "vi-externo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-externo",
      "name": "check-button-ext",
      "switch": ""
    },
    model: {
      value: _vm.novedad.externo,
      callback: function callback($$v) {
        _vm.$set(_vm.novedad, "externo", $$v);
      },
      expression: "novedad.externo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_vm.novedad.externo ? _c('b-form-group', {
    attrs: {
      "label": "Ruta URL externa",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Link2Icon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-url",
      "placeholder": "Ruta url externa"
    },
    model: {
      value: _vm.novedad.ruta,
      callback: function callback($$v) {
        _vm.$set(_vm.novedad, "ruta", $$v);
      },
      expression: "novedad.ruta"
    }
  })], 1)], 1) : _c('b-form-group', {
    attrs: {
      "label": "Subir archivo",
      "label-for": "vi-file"
    }
  }, [_vm.uploadingFile ? _c('p', [_vm._v("Subiendo archivo...")]) : _c('b-form-file', {
    attrs: {
      "id": "vi-file",
      "accept": _vm.fileType(),
      "placeholder": _vm.novedad.ruta
    },
    on: {
      "change": _vm.onChangeFile
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.uploadingFile
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }